import * as React from "react"
import { useState,useEffect } from "react"
import { Link } from "gatsby"

import bannerMainImg from "../../images/banner/banner-img-11.png"
import bannerBgImg from "../../images/banner/banner-bg-7.jpg"

import flagImg1 from "../../images/flag/flag-8.jpg"
import flagImg2 from "../../images/flag/flag-8.jpg"

import bannerShape10 from "../../images/banner/banner-shape-10.png"
import bannerShape11 from "../../images/banner/banner-shape-11.png"
import bannerShape12 from "../../images/banner/banner-shape-12.png"

import userImg1 from "../../images/reviews/reviews-17.png"
import userImg2 from "../../images/reviews/reviews-18.png"
import userImg3 from "../../images/reviews/reviews-19.png"
import userImg4 from "../../images/reviews/reviews-20.png"
import axios from "axios"

const  url = "https://amalswed.com/json.php";
const MainBannner = () => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [rate, setRate] = useState([]);
useEffect(()=>{
const fetchData = async ()=>{
 const result = await fetch(url,{
  method: 'GET',
  headers: {
    'Accept': 'application/json'
  }})
  const result2 = await result.json();
 
  setRate(result2[0].rate)
 console.log(result2[0].rate)
}
fetchData();
},[])
  
function handleChange(event) {
  setPosts(event.target.value);
}
  return (
    <>
      <div 
        id="home" 
        className="currency-exchaange-banner"
        style={{ backgroundImage: `url(${bannerBgImg})` }}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div 
              className="col-lg-6" 
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200" 
              data-aos-once="true"
            >
              <div className="currency-exchange-banner-content">
                <span>Välkomna till Amal Express</span>
                <h1>Skicka pengar till dina nära och kära </h1>
                <p>
                  Skicka pengar säkert till utlandet med Amal Express.
                  
                  <div className="App">
               
            </div>
                </p>

                

                
                <img
                  src={bannerShape12}
                  className="banner-shape-4"
                  alt="Image"
                />
              </div>
            </div>

            <div 
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="400" 
              data-aos-once="true"
            >
              <div className="currency-exchange-banner-image">
                <img src={bannerMainImg} alt="Image" />

                <div className="currency-converter-form">
                  <h3>Priskalkylator</h3>
                  <form onSubmit={e => e.preventDefault()}>
                  <div className="form-group icon-add1">
                      <label className="form-label">Jag skickar</label>
                      <input className="form-select form-control" onChange={handleChange}/>
                        
                      <img src={flagImg1} alt="Image" />
                    </div>
                    <div className="form-group icon-add1">
                      <label className="form-label">USD -> SEK</label>
                    
                    </div>

                    <div className="form-group icon-add2">
                      <label className="form-label" style={{fontSize:"50px"}}> 
                      
                      { 
                         1.04 *posts * rate+ " kr"
                      }
                      </label>
                     
                    </div>

                    
                  </form>
                </div>

                {/* Shape Images */}
                <img
                  src={bannerShape10}
                  className="banner-shape-1"
                  alt="Image"
                />
                <img
                  src={bannerShape11}
                  className="banner-shape-2"
                  alt="Image"
                />
                <img
                  src={bannerShape12}
                  className="banner-shape-3"
                  alt="Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainBannner
