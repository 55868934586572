import * as React from "react"
import { Link } from "gatsby"

import teamImg1 from "../../images/team/team-20.jpg"
import teamImg2 from "../../images/team/team-21.jpg"
import teamImg3 from "../../images/team/team-22.jpg"

const WhatWeDo = () => {
  return (
    <>
      <div id="services" className="exchange-specialists-area pt-100 pb-70">
        <div className="container">
          <div className="exchange-section-title">
            <span>Välkomna till Amal Express</span>
            <h2>Bästa sätten att skicka pengar internationellt</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div 
                className="exchange-specialist-card" 
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200" 
                data-aos-once="true"
              >
                <div className="specilist-img">
                  <img src="https://amalexpp.amalsweden.com/wp-content/uploads/2019/11/IMG_84301.jpg" alt="Image" />
                </div>
                <div className="specialist-content">
                  <h3>UTBILDNING AMAL EXPRESS GÖTEBORG</h3>
                  
                  <Link to="#">
                  Läs mer<i className="flaticon-right-arrow-1"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div 
                className="exchange-specialist-card"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="400" 
                data-aos-once="true"
              >
                <div className="specilist-img">
                  <img src="https://amalexpp.amalsweden.com/wp-content/uploads/2019/12/FARAXAN-Y1.jpg" alt="Image" />
                </div>
                <div className="specialist-content">
                  <h3>AMAL EXPRESS EVENT</h3>
                 
                  <Link to="#">
                  Läs mer<i className="flaticon-right-arrow-1"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div 
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600" 
              data-aos-once="true"
            >
              <div className="exchange-specialist-card">
                <div className="specilist-img">
                  <img src="https://amalexpp.amalsweden.com/wp-content/uploads/2019/10/clients.jpg" alt="Image" />
                </div>
                <div className="specialist-content">
                  <h3>BLI PRIVAT KUND HOS OSS</h3>
                 
                  <Link to="#">
                    Läs mer<i className="flaticon-right-arrow-1"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhatWeDo
