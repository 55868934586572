import * as React from "react"
import { Link } from "gatsby"

import logo from "../../images/logo/white-logo.png"

const Footer = () => {
  return (
    <>
      <div className="nft-footer-area bg-color-0a0a0a pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="logo-area style6">
              
                  AMAL EKONOMISK FÖRENING
             
                <p>


© COPYRIGHT 2019 AMALSWEDEN
                </p>

                <div className="social-links">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="flaticon-facebook-1"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.twitter.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="flaticon-twitter-3"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://google.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="flaticon-google-plus"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://linkedin.com/?lang=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="flaticon-linkedin-1"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="footer-widjet style6 pl-20">
                
                <div className="link-list">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="flaticon-next"></i>AMAL EXPRESS EKONOMISK FÖRENING STÅR UNDER FINANSINSPEKTIONENS TILLSYN MED INSTITUTNUMMER 45509
                      </Link>
                    </li>
                   
                  </ul>
                </div>
              </div>
            </div>

            

            <div className="col-lg-3 col-sm-6">
              <div className="agency-subscribe style3">
                <h3>Subscribe Newsletter</h3>
                <form className="newsletter-form" onSubmit={e => e.preventDefault()}>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your Email"
                    name="EMAIL"
                    required
                  />
                  <button className="exchange-default-btn btn" type="submit">
                    Join Us!
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-copy-right-area bg-color-efedf0 text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>
                © Amalsweden{" "}
              
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
