import * as React from "react"

import icon1 from "../../images/choose/choose-8.png"
import icon2 from "../../images/choose/choose-9.png"
import icon3 from "../../images/choose/choose-10.png"
import icon4 from "../../images/choose/choose-11.png"

const WhyChooseUs = () => {
  return (
    <>
      <div className="exchainge-choose-area pt-100 pb-70">
        <div className="container">
          <div className="exchange-section-title">
            <span>VARFÖR VÄLJA OSS</span>
            <h2>Nöjd kund är återkommande kund & vår bästa reklam</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div 
                className="exchange-choose-card"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200" 
                data-aos-once="true"
              >
                <img src={icon1} alt="Iamge" />
                <h3>BLI KUND</h3>
                <p>
                Välkommen som ny kund i Amal Express. Du får hjälp av våra duktiga medarbetare att komma igång med dina transaktioner{" "}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div 
                className="exchange-choose-card"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="400" 
                data-aos-once="true"
              >
                <img src={icon2} alt="Iamge" />
                <h3>PRISVÄRT</h3>
                <p> Alltid låga avgifter på internationella pengaöverföringar. {" "}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div 
                className="exchange-choose-card"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="600" 
                data-aos-once="true"
              >
                <img src={icon3} alt="Iamge" />
                <h3>BETALTJÄNSTER</h3>
                <p>Du som kund kan ta emot pengar i Sverige och skicka pengar på olika sätt genom att använda MOBILT BANKID, SWISH, KONTANT,BANKGIRO eller PLUSGIRO
                      {" "}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div 
                className="exchange-choose-card"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="800" 
                data-aos-once="true"
              >
                <img src={icon4} alt="Iamge" />
                <h3>Inget krångel</h3>
                <p>
                Det är enkelt att registrera sig och börja skicka pengar på ett tillförlitligt och snabbt sätt över hela världen.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhyChooseUs
